import type { CommlandApplication } from './types';
import {
  getAppNames,
  navigateToUrl,
  start,
  unregisterApplication
} from 'single-spa';
import {
  fetchUserApplications,
  isApplicationUIShell,
  isApplicationWorkspace
} from './api';
import * as utils from './utils';
import logger from '../../utils/logger';

const IMPORTMAP_ELEMENT_ID = 'commland-applications-importmap';

class Routing {
  workspaces: CommlandApplication[];
  workspaceNames: string[];
  userApplications: CommlandApplication[];
  userApplicationNames: string[];

  async init() {
    logger.info('routing: starting routing module');

    this._initEventBindings();
    await this.refreshRouting();
    start({ urlRerouteOnly: true });
    logger.info('routing: started routing module');
    window.commlandEvents.emit('commland.routing.started');

    if (window.desktop) {
      window.desktop.packages.checkWorkspacesForUpdates();
    }
  }

  async refreshRouting() {
    await this._loadApplications();
    window.commlandEvents.emit('commland.routing.refreshed');
  }

  getAvailableAppNames() {
    return getAppNames();
  }

  async removeUserApplications() {
    this.getAvailableAppNames().forEach((app) =>
      unregisterApplication(app).then(() => {
        System.delete(System.resolve(app));
      })
    );

    document.getElementById(IMPORTMAP_ELEMENT_ID)?.remove();
    this._updateApplicationsList([]);
    await this.refreshRouting();
  }

  navigate(url: string) {
    if (window.desktop) {
      window.commlandEvents.emit('commland.websites.hideAll');
    }

    navigateToUrl(`#${url}`);
  }

  async _loadApplications() {
    const applications = await fetchUserApplications();
    await this._loadImportMap(applications);
    utils.registerApplications(applications);
    await this._updateApplicationsList(applications);
  }

  async _loadImportMap(applications: CommlandApplication[]) {
    const im = document.createElement('script');
    const imports = {};
    im.id = IMPORTMAP_ELEMENT_ID;
    im.type = 'systemjs-importmap';

    for (const app of applications) {
      const { name } = app;
      const author = utils.getApplicationAuthor(app);
      const appName = `@${author}/${name}`;
      const url = await utils.getPackageUrl(app);

      if (name.startsWith('nemo/apps')) continue;
      imports[appName] = url;
    }

    im.textContent = JSON.stringify({
      imports
    });

    document.getElementById(IMPORTMAP_ELEMENT_ID)?.remove();
    document.head.appendChild(im);
    logger.info('routing: loaded applications importmap');
  }

  async _updateApplicationsList(CommlandApplications: CommlandApplication[]) {
    const uiShell: CommlandApplication[] = [];
    const workspaces: CommlandApplication[] = [];
    const applications: CommlandApplication[] = [];

    if (window.desktop) {
      window.desktop.packages.storePackagesInformation(CommlandApplications);
    }

    CommlandApplications.forEach((app) => {
      if (isApplicationUIShell(app.name)) {
        uiShell.concat(app);
      } else if (isApplicationWorkspace(app.name)) {
        workspaces.push(app);
      } else {
        applications.push(app);
      }
    });

    this.workspaces = workspaces;
    this.workspaceNames = workspaces.map((app) => {
      const author = utils.getApplicationAuthor(app);
      return `@${author}/${app.name}`;
    });

    this.userApplications = applications;
    this.userApplicationNames = applications.map((app) => {
      const author = utils.getApplicationAuthor(app);
      return `@${author}/${app.name}`;
    });
  }

  _isUserAuthenticated() {
    return window.commland.auth.isUserAuthenticated();
  }

  // if user is not authenticated redirect to /login
  // if user is authenticated but target url does not exist or
  //  user does not have access to the application redirect to /phone-workspace
  _onUserNavigationEvent(event: CustomEvent) {
    event.preventDefault();
    const areWorkspacesAvailable = this.workspaces.length > 0;
    const defaultUrl = areWorkspacesAvailable
      ? '#/phone-workspace'
      : '#/nemo/apps/phone-system';

    const targetUrl = event.detail.newUrl as string;
    const isAuthenticated = this._isUserAuthenticated();

    if (!isAuthenticated) {
      logger.debug('routing: no auth token found, redirecting to /login');
      navigateToUrl('#/login');
      return;
    }

    const urlObject = new URL(targetUrl);
    const { hash } = urlObject;
    const targetPathname = hash.slice(2);
    const targetAppName = targetPathname.split('/')[0];
    const isAppAvailable = this.getAvailableAppNames().find((app) =>
      app.endsWith(`/${targetAppName}`)
    );

    // External websites don't have a route, so we prevent
    // redirecting when navigating to them in dock
    if (targetAppName === 'websites') {
      return;
    }

    if (!isAppAvailable) {
      logger.info(
        `routing: route not found or user does not have access to application /${targetAppName}, redirecting to ${defaultUrl}`
      );
      // use cancelNavigation() instead https://single-spa.js.org/docs/api/#canceling-navigation
      navigateToUrl(defaultUrl);
      return;
    }

    if (targetAppName === 'login') {
      logger.debug(
        `routing: user tried to access /login while authenticated, redirecting to ${defaultUrl}`
      );

      // use cancelNavigation() instead https://single-spa.js.org/docs/api/#canceling-navigation
      navigateToUrl(defaultUrl);
      return;
    }

    if (window.desktop) {
      window.desktop.packages.checkApplicationForUpdates(targetAppName);
    }
  }

  _initEventBindings() {
    window.commlandEvents.on('commland.routing.refresh', () =>
      this.refreshRouting()
    );

    window.addEventListener(
      'single-spa:before-app-change',
      (event: CustomEvent) => this._onUserNavigationEvent(event)
    );
  }
}

export default Routing;
