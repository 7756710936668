import { t } from 'i18next';
import { v4 as uuid } from 'uuid';
import {
  accountsIcon,
  numbersIcon,
  pbxsIcon,
  phoneSystemIcon,
  provisionerIcon,
  userportalIcon
} from '../../../assets/apps';
import {
  chatWorkspaceIcon,
  conferencesWorkspaceIcon,
  contactsWorkspaceIcon,
  faxesWorkspaceIcon,
  phoneWorkspaceIcon
} from '../../../assets/workspaces';
import { KazooSDK } from '@commland/kazoo-js-sdk';
import { getIsChatWorkspaceEnabled } from '../utils';
import { CommlandApplication } from '../types';

const buildWorkspaceBaseDocument = (
  name: string
): Partial<CommlandApplication> => {
  const id = uuid();

  return {
    id,
    uuid: id,
    name,
    version: '2.7.0',
    i18n: {
      'en-US': {
        label: t(`routing.workspaces.${name}`),
        features: [],
        extended_description: name,
        description: name
      }
    }
  };
};

const buildApplicationBaseDocument = (
  name: string
): Partial<CommlandApplication> & { hidden: true } => {
  const id = uuid();

  return {
    id,
    uuid: id,
    name,
    version: '2.7.0',
    hidden: true,
    i18n: {
      'en-US': {
        label: name,
        features: [],
        extended_description: name,
        description: name
      }
    }
  };
};

const buildNemoApplications = () => {
  return [
    {
      icon: accountsIcon,
      icon_url: accountsIcon,
      id: 'accounts-manager',
      uuid: 'accounts-manager',
      name: 'nemo/apps/accounts-manager',
      url: '/nemo/apps/accounts-manager',
      version: '1.0.1',
      i18n: {
        'en-US': {
          label: 'Accounts Manager',
          features: [],
          extended_description: 'Accounts Manager',
          description: 'Accounts Manager'
        }
      }
    },
    {
      icon: provisionerIcon,
      icon_url: provisionerIcon,
      id: 'provisioner',
      uuid: 'provisioner',

      name: 'nemo/apps/provisioner',
      url: '/nemo/apps/provisioner',
      version: '1.0.1',
      i18n: {
        'en-US': {
          label: 'Provisioner',
          features: [],
          extended_description: 'Provisioner',
          description: 'Provisioner'
        }
      }
    },
    {
      icon: phoneSystemIcon,
      icon_url: phoneSystemIcon,
      id: 'phone-system',
      uuid: 'phone-system',
      name: 'nemo/apps/phone-system',
      url: '/nemo/apps/phone-system',
      version: '1.0.1',
      i18n: {
        'en-US': {
          label: 'Phone System',
          features: [],
          extended_description: 'Phone System',
          description: 'Phone System'
        }
      }
    },
    {
      icon: numbersIcon,
      icon_url: numbersIcon,
      id: 'numbers',
      uuid: 'numbers',
      name: 'nemo/apps/numbers',
      url: '/nemo/apps/numbers',
      version: '1.0.0',
      i18n: {
        'en-US': {
          label: 'Numbers',
          features: [],
          extended_description: 'Numbers',
          description: 'Numbers'
        }
      }
    },
    {
      icon: pbxsIcon,
      icon_url: pbxsIcon,
      id: 'sip-trunking',
      uuid: 'sip-trunking',
      name: 'nemo/apps/sip-trunking',
      url: '/nemo/apps/sip-trunking',
      version: '1.0.0',
      i18n: {
        'en-US': {
          label: 'SIP Trunking',
          features: [],
          extended_description: 'SIP Trunking',
          description: 'SIP Trunking'
        }
      }
    }
  ];
};

const fetchApplications = () => {
  return [
    buildApplicationBaseDocument('account-settings'),
    buildApplicationBaseDocument('settings'),
    ...buildNemoApplications(),
    {
      ...buildApplicationBaseDocument('nemo'),
      icon: userportalIcon,
      author: 'commland',
      hidden: true,
      i18n: {
        'en-US': {
          label: 'Admin Portal',
          features: [],
          extended_description: 'Admin Portal',
          description: 'Admin Portal'
        }
      }
    }
    /*  {
      ...buildApplicationBaseDocument('admin-portal'),
      author: 'ooma',
      hidden: false,
      i18n: {
        'en-US': {
          label: 'Admin Portal',
          features: [],
          extended_description: 'Admin Portal',
          description: 'Admin Portal'
        }
      }
    } */
  ];
};

const fetchUIShell = () => {
  return [
    buildApplicationBaseDocument('toolbar'),
    buildApplicationBaseDocument('navbar')
  ];
};

/**
 *  the version property is meant for the dock to know
 *  when a workspace document has been updated and if/when the url
 *  has changed, i.e. /userportal/conferences to /conferences-workspace
 */
const fetchWorkspaces = async () => {
  if (
    !KazooSDK.credentials ||
    !KazooSDK.credentials.account_name ||
    !KazooSDK.credentials.credentials
  ) {
    return [];
  }

  const isChatWorkspaceEnabled = await getIsChatWorkspaceEnabled();

  return [
    ...(isChatWorkspaceEnabled
      ? [
          {
            ...buildWorkspaceBaseDocument('chat-workspace'),
            icon: chatWorkspaceIcon,
            phase: 'beta'
          }
        ]
      : []),
    {
      ...buildWorkspaceBaseDocument('conferences-workspace'),
      icon: conferencesWorkspaceIcon,
      phase: 'beta'
    },
    {
      ...buildWorkspaceBaseDocument('contacts-workspace'),
      icon: contactsWorkspaceIcon
    },
    {
      ...buildWorkspaceBaseDocument('faxes-workspace'),
      icon: faxesWorkspaceIcon
    },
    {
      ...buildWorkspaceBaseDocument('phone-workspace'),
      icon: phoneWorkspaceIcon
    },
    {
      id: uuid(),
      uuid: uuid(),
      name: 'screenpops',
      version: '2.4.0',
      hidden: true
    },
    {
      ...buildWorkspaceBaseDocument('external-websites'),
      url: '/websites',
      hidden: true
    }
  ];
};

const fetchDefaultApplications = async () => {
  /* const uiShell = fetchUIShell();
  const applications = fetchApplications();
  const workspaces = await fetchWorkspaces();

  return [...uiShell, ...workspaces, ...applications]; */
  const uiShell = fetchUIShell();
  const applications = fetchApplications();

  return [...uiShell, ...applications];
};

export default fetchDefaultApplications;
