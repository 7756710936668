import type { CommlandApplication } from './types';
import { registerApplication } from 'single-spa';
import {
  constructApplications as constructSingleSpaApplications,
  constructRoutes,
  constructLayoutEngine
} from 'single-spa-layout';
import { KazooSDK } from '@commland/kazoo-js-sdk';
import { isApplicationUIShell, isApplicationWorkspace } from './api';
import logger from '../../utils/logger';
import { getPlatform } from '../../utils/mobile';

const EXCLUDED_UI_SHELL_ROUTES = [
  '#/login',
  '#/cluster-manager/windows',
  '#/shipyard/windows',
  '#/screenpops-admin/windows',
  '#/conferences-admin/windows',
  '#/user-portal/windows'
];

const showExcept = (routes) => (location) => {
  return routes.every((route) => !location.hash.startsWith(route));
};

export const getPackageUrl = async (app: CommlandApplication) => {
  const overridesKey = 'import-map-overrides-disabled';
  const disabledOverridesString = localStorage.getItem(overridesKey);
  const disabledOverrides = JSON.parse(disabledOverridesString ?? '[]');

  const { name } = app;
  const author = getApplicationAuthor(app);
  const appName = `@${author}/${name}`;
  const urlFromStorage = getImportUrlFromStorage(appName);
  const isOverrideDisabled = disabledOverrides.find((item) => item === appName);
  const packageName = `${author}-${name}`;

  // TODO: testing only
  /*  if (packageName === 'commland-navbar') {
    return 'http://127.0.0.1:4040/ui/navbar/commland-navbar.js';
  } */

  let baseUrl = '/applications';
  if (getPlatform() === 'ios') {
    baseUrl = './localhost/applications';
  }

  let url = `${baseUrl}/${name}/${packageName}.js`;
  // TODO: this is currently not working because of backend dependency
  // uncomment when backend is ready
  /*  const crossbarUrl = window.config.API_URL.replace('/v2', '');
    const parsedPackageName = packageName.replace(/-/g, '_'); 
    let url = `${crossbarUrl}/ui/${parsedPackageName}/${packageName}.js`;
 */
  if (window.desktop) {
    const base = await window.desktop.packages.getPackageLocation(name);
    url = `${base}/${packageName}.js`;
  }

  if (!isOverrideDisabled && urlFromStorage) {
    logger.debug(
      `routing: using override url from localStorage for @${author}/${name}`
    );

    url = urlFromStorage;
  }

  return url;
};

export const getApplicationAuthor = (app: CommlandApplication) => {
  let author = app.author?.toLowerCase();
  if (!app.author || app.author.toLowerCase() === '2600hz') {
    author = 'commland';
  }

  return author;
};

const constructApplicationRoutes = (
  kazooApplications: CommlandApplication[]
) => {
  return constructRoutes({
    mode: 'hash',
    base: '/',
    containerEl: 'body',
    routes: kazooApplications.map((app) => {
      const { name } = app;
      const author = getApplicationAuthor(app);
      const parsedName = `@${author}/${name}`;
      const isUIShell = isApplicationUIShell(name);
      const isWorkspace = isApplicationWorkspace(name);

      if (isUIShell || isWorkspace) {
        return {
          type: 'application',
          name: parsedName
        };
      }

      if (name.startsWith('nemo/apps')) {
        return {
          type: 'route',
          path: name,
          routes: [
            {
              type: 'application',
              name: '@commland/nemo'
            }
          ]
        };
      }

      return {
        type: 'route',
        path: name,
        routes: [
          {
            type: 'application',
            name: parsedName
          }
        ]
      };
    })
  });
};

const constructApplications = (
  routes: ReturnType<typeof constructApplicationRoutes>
) => {
  return constructSingleSpaApplications({
    routes,
    loadApp: (app) => System.import(app.name)
  });
};

export const registerApplications = (applications: CommlandApplication[]) => {
  const routes = constructApplicationRoutes(applications);
  const singleSpaApplications = constructApplications(routes);

  constructLayoutEngine({ routes, applications: singleSpaApplications });

  singleSpaApplications.forEach((app) => {
    if (isApplicationUIShell(app.name)) {
      return registerApplication({
        ...app,
        activeWhen: showExcept(EXCLUDED_UI_SHELL_ROUTES)
      });
    }

    registerApplication(app);
  });
};

const getImportUrlFromStorage = (app: string) => {
  try {
    return localStorage.getItem(`import-map-override:${app}`);
  } catch {
    return null;
  }
};

export const getIsChatWorkspaceEnabled = async () => {
  try {
    const {
      data: { data }
    } = await KazooSDK.streamChatStatus();

    return data?.enabled ?? false;
  } catch (error) {
    logger.error('Error retrieving chat module status', error);
    return false;
  }
};
