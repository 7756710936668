import type { CommlandApplication } from '../types';
import { KazooSDK } from '@commland/kazoo-js-sdk';
import logger from '../../../utils/logger';
import { v4 as uuid } from 'uuid';
import { getIconURL } from './utils';
import fetchDefaultApplications from './defaults';

const ALPHA_APPLICATIONS = ['webhooks', 'branding'];

const BETA_APPLICATIONS = [
  'conferences-admin',
  'screenpops-admin',
  'pivot',
  'ms-teams'
];

export const fetchUserApplications = async (): Promise<
  CommlandApplication[]
> => {
  const accountId = KazooSDK.getAccountId();
  const userId = KazooSDK.getUserId();
  const isUserAuthenticated = window.commland.auth.isUserAuthenticated();

  if (!isUserAuthenticated) {
    logger.info('User is not authenticated, returning login');

    return [
      {
        id: uuid(),
        uuid: uuid(),
        name: 'login',
        version: '-',
        source_url: 'http://127.0.0.1:4040/ui/login',
        i18n: {
          'en-US': {
            label: 'Login',
            features: [],
            extended_description: 'login',
            description: 'login'
          }
        },
        allowed_users: 'all',
        screenshots: [],
        published: true,
        price: 0,
        phase: 'gold',
        license: '',
        icon: '',
        icon_url: '',
        authority: '',
        author: '',
        api_url: '',
        masqueradable: false,
        account_id: false
      }
    ];
  }

  try {
    const {
      data: { data: applications }
    } = await KazooSDK.get(
      `/accounts/${accountId}/users/${userId}/applications/commland_ui`
    );

    const defaultApplications = await fetchDefaultApplications();
    const apps = [...defaultApplications, ...applications];

    // @ts-expect-error: Temporary
    return apps.map((application: CommlandApplication) => {
      let phase = 'gold';

      // Workaround while we find a way to automate in which state an application is using the api - DUPT-441
      // The api does support the phase tag, but we still don't know who or how the apps are gonna be installed for commland_ui app type
      const isBetaApp = BETA_APPLICATIONS.includes(application.name);
      const isAlphaApp = ALPHA_APPLICATIONS.includes(application.name);
      if (isBetaApp) {
        phase = 'beta';
      } else if (isAlphaApp) {
        phase = 'alpha';
      }

      return {
        ...application,
        phase,
        icon_url: application.icon_url ?? getIconURL(application.id)
      };
    });
  } catch (error) {
    logger.error('There was an error retrieving comm.land apps', error);
    return [];
  }
};
